import React from "react";
import { PostHogClient } from "./posthog-client";
import { slugToFeatureFlagList } from "~/data/experiments.json";
import type { PageData } from "~/types";
import crypto from "crypto";
export type experimentValType = "test" | "control" | boolean;

export type PosthogBootstrapDataType = { [key: string]: experimentValType };
export const ExperimentContext =
	React.createContext<PosthogBootstrapDataType | null>(null);

export function ExperimentProvider({
	children,
	posthogBootstrapData,
}: {
	children: React.ReactNode;
	posthogBootstrapData: PosthogBootstrapDataType;
}) {
	return (
		<ExperimentContext.Provider value={posthogBootstrapData}>
			{children}
		</ExperimentContext.Provider>
	);
}

export type URLExperimentsListType = {
	[key: string]: string;
};

export async function getPosthogBootstrapData(request: Request) {
	let distinctId;
	const projectAPIKey = ENV.POSTHOG_PUBLIC_KEY;
	const cookieName = `ph_${projectAPIKey}_posthog`;
	const cookieString = request.headers.get("Cookie") || "";
	const cookieMatch = cookieString.match(new RegExp(cookieName + "=([^;]+)"));

	if (cookieMatch && Boolean(cookieMatch)) {
		const parsedValue = JSON.parse(decodeURIComponent(cookieMatch[1]));
		if (parsedValue && parsedValue.distinct_id) {
			distinctId = parsedValue.distinct_id;
		} else {
			distinctId = crypto.randomUUID();
		}
	} else {
		distinctId = crypto.randomUUID();
	}

	const client = PostHogClient();

	const flags = await client?.getAllFlags(distinctId);
	const posthogBootstrap = {
		distinctID: distinctId,
		featureFlags: flags,
	};

	return posthogBootstrap.featureFlags;
}

export function getExperimentVariant({
	pageFeatureFlag,
	currentUrl,
	posthogBootstrap,
}: {
	pageFeatureFlag: Record<typeof currentUrl, string>;
	currentUrl: string;
	posthogBootstrap: PosthogBootstrapDataType | null;
}): experimentValType | null {
	const featureFlagKey = pageFeatureFlag[currentUrl];

	if (!posthogBootstrap || !featureFlagKey) return null;

	return posthogBootstrap[featureFlagKey] || null;
}

export function getPageTrackingExperimentData({
	pageData,
	posthogBootstrapData,
	path,
}: {
	pageData?: PageData;
	posthogBootstrapData?: PosthogBootstrapDataType | null;
	path: string;
}) {
	if (
		posthogBootstrapData &&
		(slugToFeatureFlagList as URLExperimentsListType)[path] &&
		posthogBootstrapData[
			(slugToFeatureFlagList as URLExperimentsListType)[path]
		]
	) {
		return {
			featureFlagName: (slugToFeatureFlagList as URLExperimentsListType)[
				path
			],
			experimentVariant:
				posthogBootstrapData[
					(slugToFeatureFlagList as URLExperimentsListType)[path]
				],
		};
	}

	if (pageData?.experiment) {
		return {
			featureFlagName: pageData.experiment.featureFlagKey,
			experimentVariant:
				typeof pageData?.experiment?.variant === "string"
					? pageData?.experiment?.variant
					: "",
		};
	} else {
		return null;
	}
}
